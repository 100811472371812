import tableNav from '../../components/tableNav.vue' //底部导航栏
export default {
    name: 'user',
    components: {
        tableNav
    },
    data() {
        return {
            user_data: {}
        }
    },
    created() {
        document.title = "我的";
        this.$UtilsApi.memberInfo({}).then(res => {
            console.log(res.data);
            if (res.data.code == 0) {
                this.user_data = res.data.data;
                console.log(this.user_data)
            }
        })

    },
    methods: {
        toMallList() {
            localStorage.removeItem("user_token");
            window.location.replace('//paradise.h5.airland100.com/#/home?mall_id=' + localStorage.getItem('mall_id'));
        },
        toAdminInfo() {
            this.$router.push({
                name: 'adminInfo',
            })
        },
        toCoupon() {
            this.$router.push({
                name: 'coupon_list',
            })
        },
        toOrder() {
            this.$router.push({
                name: 'order',
            })
        }
    },
}