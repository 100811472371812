<template>
  <div class="tableNav">
    <div
      class="tableNavItem"
      v-for="(item, index) in tableData"
      :key="index"
      @click="toTabBar(item, index)"
    >
      <component
        v-bind:is="item.component"
        :theme="'outline'"
        :size="_scale(16)"
        :fill="tableKey == index ? '#FFB230' : '#999AAE'"
        :strokeWidth="4"
      ></component>
      <div :style="{ color: tableKey == index ? '#FFB230' : '#999AAE' }">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { Home, People } from "@icon-park/vue"; //icon图标

export default {
  props: {
    tableKey: String,
  },
  components: {
    Home,
    People,
  },
  data() {
    return {
      tableData: {
        home: {
          name: "门票列表",
          imageSrc: "",
          component: Home,
        },
        user: {
          name: "我的",
          imageSrc: "",
          component: People,
        },
      },
    };
  },
  methods: {
    //tab页面跳转
    toTabBar(item, index) {
      let _this = this;
      _this.$router.replace({
        name: index,
        query: _this.$route.query,
      });
    },
  },
};
</script>
<style scoped>
.i-icon {
  display: flex;
}
.tableNav {
  width: 351rem;
  height: 59rem;
  background: #ffffff;
  border-radius: 14rem;
  position: fixed;
  bottom: 18rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 0 80.5rem;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  z-index: 999;
}
.tableNavItem {
  font-family: PingFangTC, PingFangTC-Semibold;
  font-weight: 600;
}
.tableNavItem div {
  font-size: 12rem;
  color: #999aae;
  text-align: center;
  margin-top: 5rem;
}
.tableNavItem .i-icon {
  justify-content: center;
}
</style>
